<template>
  <a-layout class='index animated fadeIn'>
    <a-card class="mb10">
      <a-button size='small' @click="()=>{$router.go(-1)}"> <LeftOutlined />返回 </a-button>
      <span class="ml15 card_title" v-if='$route.query.type == "look"'>代理商详情</span>
      <span class="ml15 card_title" v-else>{{$route.query.type == 'add' ? '添加':'修改'}}代理商</span>
    </a-card>
    <a-card>
      <a-form
        ref="ruleForm"
        :model="form"
        :rules="rules"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
      >
        
        <a-form-item ref="company" label="公司名称" name="company">
            <a-input v-model:value="form.company" placeholder='请输入公司名称' :readonly='$route.query.type == "look"' />
        </a-form-item>
        <a-form-item ref="level_id" label="代理商级别" name="level_id">
            <a-select v-model:value="form.level_id" placeholder="请选择代理商级别" :disabled='$route.query.type == "look"'>
                <a-select-option v-for='item in levelList' :key="item.id" :value="item.id">
                    {{item.level_name}}
                </a-select-option>
            </a-select>
        </a-form-item>
        <a-form-item ref='signingTime' label="签约时间" name="signingTime" >
            <a-range-picker style="width: 100%;" v-model:value="time" @change='onTimeChange' valueFormat='YYYY-MM-DD' format='YYYY-MM-DD' :disabled='$route.query.type == "look"' />
        </a-form-item>
        <a-form-item ref="contact_name" label="联系人" name="contact_name" >
            <a-input v-model:value="form.contact_name" placeholder='请输入代理商联系人姓名' :readonly='$route.query.type == "look"' />
        </a-form-item>
        <a-form-item ref="contact_phone" label="手机号" name="contact_phone" >
            <a-input v-model:value="form.contact_phone" placeholder='请输入代理商联系人手机号' :readonly='$route.query.type == "look"' v-inputFilter:int  />
        </a-form-item>
        <a-form-item ref="account" label="账号" name="account" help='账号只能是邮箱或字母数字组合'>
            <a-input v-model:value="form.account" placeholder='请输入账号' :readonly='$route.query.type == "look"' v-inputFilter:snem />
        </a-form-item>
        <a-form-item ref="password" label="密码" name="password" v-if='$route.query.type =="add"'>
            <a-input-password v-model:value="form.password" placeholder='请输入密码' />
        </a-form-item>
        <a-form-item ref="repassword" label="再次输入密码" name="repassword" v-if='$route.query.type =="add"'>
            <a-input-password v-model:value="form.repassword" placeholder='请再次输入密码' />
        </a-form-item>
        <a-form-item ref="address" label="详细地址" name="address" >
            <div class="s_flex_bian_c s_wid100 mb15">
                <a-cascader
                    :disabled='$route.query.type == "look"'
                    v-model:value="region"
                    :field-names="{ label: 'name', value: 'id', children: 'children' }"
                    :options="cityList"
                    placeholder="请选择地址"
                    @change="onChangeAddress"
                />
            </div>
            <a-input v-model:value="form.address" placeholder='请输入详细地址' :readonly='$route.query.type == "look"' />
        </a-form-item>
        <a-form-item ref="website_url" label="官方网站" name="website_url" >
            <a-input v-model:value="form.website_url" addon-before="https://" placeholder='请输入官方网站网址' :readonly='$route.query.type == "look"' />
        </a-form-item>
        <a-form-item ref="industry" label="所属行业" name="industry">
            <a-select v-model:value="form.industry" placeholder="请选择所属行业" :disabled='$route.query.type == "look"'>
                <a-select-option v-for='item in industrysList' :key="item" :value="item" >
                    {{item}}
                </a-select-option>  
            </a-select>
        </a-form-item>
        <a-form-item ref="company_email" label="E-mail" name="company_email">
            <a-input v-model:value="form.company_email" placeholder='请输入公司邮箱' :readonly='$route.query.type == "look"'/>
        </a-form-item>
        <a-form-item ref="company_phone" label="公司联系电话" name="company_phone">
            <a-input v-model:value="form.company_phone" placeholder='请输入公司联系电话' :readonly='$route.query.type == "look"'/>
        </a-form-item>
        <a-form-item ref="domain" label="自定义域名" name="domain">
            <a-input v-model:value="form.domain" placeholder='请输入自定义域名' :readonly='$route.query.type == "look"'/>
        </a-form-item>
        <a-form-item ref="platform_name" label="自定义平台名" name="platform_name">
            <a-input v-model:value="form.platform_name" placeholder='请输入自定义平台名称' :readonly='$route.query.type == "look"' />
        </a-form-item>
        <a-form-item ref="copyright" label="自定义版权信息" name="copyright">
            <a-input v-model:value="form.copyright" placeholder='请输入自定义版权信息' :readonly='$route.query.type == "look"' />
        </a-form-item>
        <a-form-item ref="name" label="后台logo" name="name" help='图片大小不超过2M，支持jpg/png格式'>
            <a-upload
                v-model:fileList="adminfileList"
                name="file"
                accept=''
                list-type="picture-card"
                class="avatar-uploader"
                :action="baseUrl+'upload/alone'"
                :headers='header'
                @change="adminChange"
                @preview="handlePreview"
                :beforeUpload="beforeUpload"
                :disabled='$route.query.type == "look"'
            >
                <div v-if='adminfileList.length == 0'>
                    <plus-outlined />
                    <div class="ant-upload-text">上传后台logo</div>
                </div>
            </a-upload>
        </a-form-item>
        <a-form-item ref="name" label="登录logo" name="name" help='图片大小不超过2M，支持jpg/png格式'>
            <a-upload
                v-model:fileList="loginfileList"
                name="file"
                list-type="picture-card"
                class="avatar-uploader"
                accept='image/jpeg,image/png,image/jpg'
                :action="baseUrl+'upload/alone'"
                :headers='header'
                @change="loginChange"
                @preview="handlePreview"
                @beforeUpload="beforeUpload"
                :disabled='$route.query.type == "look"'
            >
                <div v-if='loginfileList.length == 0'>
                    <plus-outlined />
                    <div class="ant-upload-text">上传登录logo</div>
                </div>
            </a-upload>
        </a-form-item>
        <a-form-item ref="name" label="标题logo" name="name" help='只支持ico格式'>
            <a-upload
                v-model:fileList="titlefileList"
                name="file"
                accept='image/x-icon'
                list-type="picture-card"
                class="avatar-uploader"
                :action="baseUrl+'upload/alone'"
                :headers='header'
                @change="titleChange"
                @beforeUpload="beforeUpload"
                @preview="handlePreview"
                :disabled='$route.query.type == "look"'
            >
                <div v-if='titlefileList.length == 0'>
                    <plus-outlined />
                    <div class="ant-upload-text">上传标题logo</div>
                </div>
            </a-upload>
        </a-form-item>
        <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
            <img alt="example" style="width: 100%" :src="previewImage" />
        </a-modal>
        <a-form-item :wrapper-col="{ span: 8, offset: 2 }" v-if='$route.query.type!=="look"'>
          <a-button type="primary" @click="onSubmit">
            保存
          </a-button>
        </a-form-item>
      </a-form>
    </a-card>
  </a-layout>
</template>

<script>
import { LeftOutlined,PlusOutlined } from '@ant-design/icons-vue';
import { ref,onMounted,reactive, toRefs,inject } from 'vue';
import { setAgentAdd,getAgentLevelList,getAgentMore,setAgentEdit,getAgentIndustrys } from "@/api/agent";
import { getArea } from "@/api/public";
import { message } from 'ant-design-vue';
import { $iscode } from "@/utils/app";
import store from "@/store";

import config from '@/config'
import {  getToken } from '@/libs/util'
const baseUrl = config.baseUrl[process.env.VUE_APP_type]
const header = {apiAuth: getToken()}
// 初始化默认筛选项数值
let defForm = {
    company:'',
    level_id: undefined,
    signing_start_time:'',
    signing_end_time:'',
    contact_name:'',
    contact_phone:'',
    account:'',
    password:'',
    repassword:'',
    province:undefined,
    city:undefined,
    region:undefined,
    address:'',
    website_url:'',
    industry:undefined,
    company_email:'',
    company_phone:'',
    domain:'',
    platform_name:'',
    copyright:'',
    admin_logo:'',
    login_logo:'',
    title_logo:''
}
export default {
    components: {
        PlusOutlined,
        LeftOutlined,
    },
    setup() {
        let repassword = async (rule, value, callback) => {
            if (value === '') {
                return Promise.reject('请再次输入密码');
            }else if (value !== form.value.password) {
                return Promise.reject("两次密码输入不一致");
            } else {
                return Promise.resolve();
            }
        };
        let signingTime = async(rule, value, callback) => {
            if (form.value.signing_start_time == '' || form.value.signing_end_time == '') {
                return Promise.reject('请选择签约时间');
            }else{
                return Promise.resolve();
            }
        };
        const form = ref({...defForm});
        const state = reactive({
            info: store.state.user.info,
            address:[],
            region:[],
            cityList:[],
            baseUrl,
            header,
            adminfileList: [],
            loginfileList: [],
            titlefileList: [],
            levelList:[],
            time:[],
            industrysList:[],
            loading: false,
            imageUrl: '',
            previewVisible: false,
            previewImage: '',
            labelCol: { span: 2 },
            wrapperCol: { span: 6 },
            rules: {
                company: [
                    { required: true, message: '请输入公司名称', trigger: 'blur' },
                    { min: 6, max: 20, message: '公司名称长度在6到20个字符之间', trigger: 'blur' },
                ],
                level_id: [{ required: true, message: '请选择代理商级别' }],
                signingTime:[{ required: true, validator: signingTime, trigger: 'change' },],
                contact_name: [
                    { required: true, message: '请输入代理商联系人名称', trigger: 'blur' },
                    { min: 2, max: 10, message: '代理商联系人名称长度在2到10个字符之间', trigger: 'blur' },
                ],
                contact_phone: [
                    { required: true, message: '请输入代理商联系人手机号', trigger: 'blur' },
                    { len: 11, message: '代理商联系人手机号长度有误', trigger: 'blur' },
                ],
                account:[
                    { required: true, message: '请输入账号', trigger: 'blur' },
                    { min: 6, max: 20, message: '账号长度在6到20个字符之间', trigger: 'blur' },
                ],
                
            }
        });
        // 默认设置一年
        const getTimer=()=>{
           var day2 = new Date();
            day2.setTime(day2.getTime());
            var s1 = day2.getFullYear()+"-" + (day2.getMonth()+1) + "-" + day2.getDate();
            var s2 = day2.getFullYear()+1+"-" + (day2.getMonth()+1) + "-" + day2.getDate();
            state.time=[s1,s2]
            form.value.signing_start_time=s1
            form.value.signing_end_time=s2
        }
        getTimer()
        const ruleForm =ref()
        const $route = inject('$route')
        const $Modal = inject('$Modal')
        const $router = inject('$router')
        const _lodash = inject("_lodash");
        // 获取省市区
        const getAreas=async()=>{
          let result=await getArea().then(res=>res)
          state.cityList=result.data
        }
        getAreas()
        //签约时间
        const onTimeChange = (e) => {
            if(e.length > 0){
                form.value.signing_start_time = e[0]
                form.value.signing_end_time = e[1]
            }else{
                form.value.signing_start_time = ''
                form.value.signing_end_time = ''
            }
        }
        //代理商等级列表
        const getLevelList = async() =>{
            let res = await getAgentLevelList().then(res => res.data);
            if ($iscode(res)) {
                state.levelList = res.data.data
            }else {
                message.error(res.msg);
            }
        }
        //获取详情
        const getMore = async (id) =>{
            const hide = message.loading('正在加载中...', 0);
            let res = await getAgentMore(id).then(res => res.data);
            if ($iscode(res)) {
                form.value = res.data
                state.time = [res.data.signing_start_time,res.data.signing_end_time]
                state.region = [res.data.province,res.data.city,res.data.region]
                state.adminfileList = res.data.admin_logo ? [{url:res.data.admin_logo.indexOf(state.info.picdomain) == -1 ?state.info.picdomain+res.data.admin_logo : res.data.admin_logo}] : []
                state.loginfileList = res.data.login_logo ? [{url:res.data.login_logo.indexOf(state.info.picdomain) == -1 ?state.info.picdomain+res.data.login_logo : res.data.login_logo}] : []
                state.titlefileList = res.data.title_logo ? [{url:res.data.title_logo.indexOf(state.info.picdomain) == -1 ?state.info.picdomain+res.data.title_logo : res.data.title_logo}] : []
                
                setTimeout(hide);
            } else {
                message.error(res.msg);
            }
        }
        //获取代理商行业
        const getIndustrys = async() =>{
            let res = await getAgentIndustrys().then(res => res.data);
            if ($iscode(res)) {
                state.industrysList = res.data
            } else {
                message.error(res.msg);
            }
        }
        //选择省市区
        const onChangeAddress = (e) =>{
            form.value.province = e[0]
            form.value.city = e[1]
            form.value.region = e[2]
        }
        //提交
        const onSubmit = () => {
            ruleForm.value.validate().then(async() => {
                let res 
                const hide = message.loading('正在加载中...', 0);
                if($route.query.type == 'add'){
                    res = await setAgentAdd({...form.value}).then(res => res.data).catch(error=>error); 
                }else{
                    res = await setAgentEdit($route.query.id,{...form.value}).then(res => res.data).catch(error=>error); 
                }
                if ($iscode(res)) {
                    message.success(res.msg);
                    setTimeout(() => {
                        $router.go(-1)
                    }, 1000);
                } else {
                    message.error(res.msg || res.response.data.msg);
                }
                setTimeout(hide);
            }).catch(error => {
                message.error('请完善表单信息');
            });
        }
        const beforeUpload=(file, fileList)=>{
            console.log(file,fileList)
            if(fileList.length>1){
                  message.error('最多上传一张图');
                return false 
            }
        }
        // 后台logo
        const adminChange = (info) => {
            if (info.file.status === 'done') {
                state.adminfileList = info.fileList.splice(info.fileList.length-1,1)
                form.value.admin_logo = info.file.response.data.path
            }
            if (info.file.status === 'error') {
                message.error('图片上传失败！');
            }
            if (info.file.status === 'removed') {
                form.value.admin_logo = ''
            }
        }
        // 登录logo
        const loginChange = (info) => {
            if (info.file.status === 'done') {
                state.loginfileList = info.fileList.splice(info.fileList.length-1,1)
                form.value.login_logo = info.file.response.data.path
            }
            if (info.file.status === 'error') {
                message.error('图片上传失败！');
            }
            if (info.file.status === 'removed') {
                form.value.login_logo = ''
            }
        }
        // 标题logo上传
        const titleChange = (info) => {
            if (info.file.status === 'done') {
                state.titlefileList = info.fileList.splice(info.fileList.length-1,1)
                form.value.title_logo = info.file.response.data.path
            }
            if (info.file.status === 'error') {
                message.error('图片上传失败！');
            }
            if (info.file.status === 'removed') {
                form.value.title_logo = ''
            }
        }
        const getBase64 = (file) => {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = error => reject(error);
            });
        }
        const  handlePreview = async (file) => {
            if (!file.url && !file.preview) {
                file.preview = await getBase64(file.originFileObj);
            }
            state.previewImage = file.url || file.preview;
            state.previewVisible = true;
        }
        const handleCancel = () => {
            state.previewVisible = false;
        }
        onMounted(() => {
            getLevelList();
            getIndustrys();
            if($route.query.type == 'edit' || $route.query.type == 'look'){
                getMore($route.query.id);
            }else{
                let rulesObj = {
                    password: [
                        { required: true, message: '请输入密码', trigger: 'blur' },
                        { min: 6, max: 20, message: '密码长度在6到20个字符之间', trigger: 'blur' },
                    ],
                    repassword: [
                        { required: true, validator: repassword, trigger: 'blur' },
                    ],
                }
                state.rules = _lodash.merge(state.rules,rulesObj)
            }
        });
        return{
            form,
            ...toRefs(state),
            ruleForm,
            onSubmit,
            adminChange,
            loginChange,
            titleChange,
            beforeUpload,
            getBase64,
            handleCancel,
            handlePreview,
            onTimeChange,
            getIndustrys,
            onChangeAddress
        }
    }
}
</script>