//代理商api

import axios from '@/utils/request'
// 获取代理商列表
export const getAgentList = (params)=>{
 return axios.request({
    url: 'agent/index',
    method: 'get',
    params
  })
};
// 获取代理商最高等级
export const getAgentMax = (params)=>{
  return axios.request({
     url: 'agent/getMaxLevelNum',
     method: 'get',
     params
   })
 };
//代理商删除
export const setAgentDel = (id)=>{
  return axios.request({
     url: 'agent/index/'+id,
     method: 'DELETE'
   })
};
//代理商禁用
export const setAgentDisable = (id,status)=>{
  return axios.request({
     url: 'agent/index/status/'+id+'/'+status,
     method: 'PUT'
   })
};
//代理商认证修改
export const setAgentAuth = (id,status)=>{
  return axios.request({
     url: 'agent/index/certificate/'+id+'/'+status,
     method: 'PUT'
   })
};
//代理商详情
export const getAgentMore = (id)=>{
  return axios.request({
     url: 'agent/index/'+id,
     method: 'get'
   })
};
//代理商添加
export const setAgentAdd = (data)=>{
  return axios.request({
     url: 'agent/index',
     method: 'post',
     data
   })
};
//代理商修改
export const setAgentEdit = (id,data)=>{
  return axios.request({
     url: 'agent/index/'+id,
     method: 'PUT',
     data
   })
};


// 获取代理商等级列表
export const getAgentLevelList = (params)=>{
  return axios.request({
     url: 'agent/level',
     method: 'get',
     params
   })
 };
 
//代理商等级详情
export const getAgentLevelMore = (id)=>{
  return axios.request({
     url: 'agent/level/'+id,
     method: 'get'
   })
};
//代理商等级修改
export const setAgentLevelEdit = (id,data)=>{
  return axios.request({
     url: 'agent/level/'+id,
     method: 'PUT',
     data
   })
};
//代理商等级添加
export const setAgentLevelAdd = (data)=>{
  return axios.request({
     url: 'agent/level',
     method: 'post',
     data
   })
};
//代理商等级删除
export const setAgentLevelDel = (id)=>{
  return axios.request({
     url: 'agent/level/'+id,
     method: 'DELETE'
   })
};
//代理商行业
export const getAgentIndustrys = ()=>{
  return axios.request({
     url: 'agent/industry',
     method: 'GET'
   })
};
// 省市区
export const getRegionList = (params)=>{
  return axios.request({
     url: 'region/lists',
     method: 'GET',
     params
   })
};