import axios from '@/utils/request'
import config from '@/config/index'
//图片上传
export const upload = (data)=>{
  return axios.request({
     url: 'upload/alone',
     method: 'post',
     data
   })
};
//获取省市区
export const getArea = (data)=>{
  return axios.request({
    url:  config.oss[process.env.VUE_APP_type]+'/area.json',
    method: 'post',
    data
  })
};